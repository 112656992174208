<template>
  <v-app
    :style="{
      height: '100%',
      background: '#DAD4c6'
    }"
  >
    <v-snackbar
      :timeout="5000"
      v-model="snackbar.mostrar"
      app
      centered
      top
      elevation="10"
      light
      style="z-index: 100000 !important; padding: 0 !important"
    >
      <v-alert border="top" :type="snackbar.color" text prominent class="mb-0">
        {{ snackbar.mensaje }}
        <template v-slot:append>
          <v-btn icon class="ml-2" @click="cerrarAlerta()">
            <v-icon :color="snackbar.color">mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
      </v-alert>
    </v-snackbar>
    
    <app-side-bar v-if="mostrarNav"></app-side-bar>
    <app-toolbar v-if="mostrarNav"></app-toolbar>

    <v-idle
      v-if="idleActivado"
      v-show="false"
      @idle="onidle"
      @remind="onremind"
      :loop="true"
      :reminders="[30]"
      :wait="5"
      :duration="360"
    />
    <global-loader />
    <v-main class="mb-10">
      <router-view />
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import SideBarVue from "./components/app/SideBarApp.vue";
import FooterVue from "./components/app/FooterApp.vue";
import ToolbarVue from "./components/app/ToolbarApp.vue";
import auth from "./auth/auth";
import { mapState, mapActions } from "vuex";
import GlobalLoader from "./components/app/GlobalLoader.vue";

export default {
  name: "App",
  components: {
    "app-side-bar": SideBarVue,
    "app-footer": FooterVue,
    "app-toolbar": ToolbarVue,
    GlobalLoader
  },
  computed: {
    ...mapState(["snackbar"]),
    env() {
      return process.env.NODE_ENV;
    },
    rutaActual() {
      return this.$route.name;
    },
    idleActivado() {
      return (
        this.rutaActual &&
        this.rutaActual !== "login" &&
        this.rutaActual !== "mantencion" &&
        this.rutaActual !== "crear_contrasena"
      );
    },
    mostrarNav() {
      return (
        this.rutaActual &&
        this.rutaActual !== "login" &&
        this.rutaActual !== "crear_contrasena" &&
        this.rutaActual !== "mantencion"
      );
    }
  },
  methods: {
    ...mapActions(["mostrarAlerta", "cerrarAlerta", "set_timeout"]),
    onidle() {
      auth.cerrarSesion();
      alert("Se cerró su sesión por inactividad");
    },
      onremind(time) {
      let payload = {
        mensaje:
          "Se va a cerrar su sesión por inactividad en " + time + " segundos",
        color: "warning",
        mostrar: true
      };
      this.mostrarAlerta(payload);
    }
  },

  mounted() {
    if (auth.isAuthenticated()) {
      if (auth.isExpireSet()) this.set_timeout();
      else {
        auth.cerrarSesion();
      }
    }
  }
};
</script>

<style lang="css">
#app {
  transition: color 0.5s, background-color 0.5s;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-bottom: 0px;
}
.ql-editor {
  padding: 0px !important;
  min-height: 1px !important;
  color: rgba(0, 0, 0, 0.8);
}
.ql-editor-p p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.flipbook {
  z-index: 899999;
  width: 95vw;
  height: 95vh;
}
.flipbook .viewport {
  width: 95vw !important;
  height: calc(100vh - 50px - 40px) !important;
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}
.blur-bg {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  height: 100%;
  width: 100%;
}
.glass {
  width: 100% !important;
  height: 100%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5) !important;
  border-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(10px) !important;
}
.glass-nav {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 62, 68, 0.75) !important;
  backdrop-filter: blur(10px);
}
.glass-top-nav {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 62, 68, 0.75) !important;
  backdrop-filter: blur(10px);
}
.alerta {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 20px;
}
.primaryGradient {
  background-image: linear-gradient(
    to right,
    #136b6f 0%,
    #2e9197 40%,
    #16575b 100%
  );
}
.v-slide-group__prev {
  display: none !important;
}
.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  margin: 0;
  min-width: 0px !important;
}
.v-snack__content {
  padding: 0 !important;
}
.v-snack__action {
  display: none;
}
.v-data-table-header th {
  padding-top: 10px !important;
  vertical-align: top !important;
}
.titulo {
  font-size: 1.5em;
  line-height: 2em;
  word-break: normal;
  font-weight: 300;
}
.titulo-card {
  font-size: 1.2em;
  line-height: 1.7em;
  word-break: normal;
  font-weight: 300;
}
.titulo-menu {
  font-size: 1.4em;
  line-height: 1.7em;
  word-break: normal;
  font-weight: 300;
}
.texto-parrafo {
  font-size: 1.05em !important;
  line-height: 1.2em;
  word-break: normal;
  font-weight: 300;
}

.texto-normal {
  font-size: 1.2em !important;
  line-height: 1.4em;
  word-break: normal;
  font-weight: 300;
}

.titulo-ayuda {
  font-size: 1em;
  line-height: 1.1em;
  word-break: normal;
  font-weight: 300;
}
.v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}
.darkGlass {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.9);
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(10px) !important;
}
.nav-props .v-list-item {
  height: 28px !important;
}
.cuotas-form .v-input__prepend-outer {
  display: flex;
  margin: 0px !important;
  height: 40px;
  width: 45px;
}
.cuotas-form .v-text-field .v-input__slot {
  border-radius: 0px 4px 4px 0px;
}
.cuotas-form .v-sheet {
  border-radius: 4px 0px 0px 4px;
}
.form-capitalize input {
  text-transform: capitalize;
}
.abs-left {
  position: absolute !important;
  left: 0px;
  padding-left: 5px;
}
.transparent-sklt .v-skeleton-loader__list-item-avatar {
  background: transparent !important;
}
.white-link a {
  color: white !important;
}
.no-action {
  pointer-events: none !important;
}
.v-list-item__title {
  white-space: normal !important;
}
.v-list-item__subtitle {
  white-space: normal !important;
}
.subcabecera {
  color: white;
  font-weight: 300;
  font-size: 19px;
  line-height: 22px;
}
.subtitulo-dialogo {
  color: black;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
}
.hover-animation {
  transition: 0.3s ease !important;
}

.hover-animation:hover {
  transform: translate3d(0, -10px, 0) !important;
}
.format-table td:first-child {
  border-left: thin solid rgba(37, 108, 116, 0.3) !important;
}
.format-table td {
  border-right: thin solid rgba(37, 108, 116, 0.3) !important;
  border-bottom: thin solid rgba(37, 108, 116, 0.3) !important;
}
.format-table th {
  border-right: thin solid rgba(37, 108, 116, 0.3) !important;
  border-top: thin solid rgba(37, 108, 116, 0.3) !important;
  border-bottom: thin solid rgba(37, 108, 116, 0.3) !important;
}
.format-table th:first-child {
  border-left: thin solid rgba(37, 108, 116, 0.3) !important;
}

.table-header th {
  background-color: rgb(36, 202, 212) !important;
  color: white !important;
  font-size: 0.9rem !important;
  font-weight: 400;
  height: 40px !important;
}
.table-header .v-data-table-header__icon {
  color: white !important;
  font-size: 0.9rem !important;
  font-weight: 400;
}

.readonly-field .v-input--is-readonly {
  pointer-events: none !important;
}

.readonly-field .v-input__append-inner {
  pointer-events: auto !important;
  cursor: default !important;
}
.readonly-field .v-input__icon--clear {
  display: none !important;
}
</style>
