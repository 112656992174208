<template>
  <v-footer color="secondary" padless :app="!mobile" inset>
    <v-row justify="space-around" no-gutters>
      <template v-if="mostrarFooter">
        <v-btn
          v-for="link in links"
          :key="link.name"
          color="white"
          text
          x-small
          :block="mobile"
          rounded
          class="my-1 text-center"
          @click="link.text ? copiarTexto(link.text) : ''"
          :href="link.link ? link.link : ''"
          target="_blank"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div>
                <v-icon small left> {{ link.icon }}</v-icon>
                <span
                  v-bind="attrs"
                  v-on="on"
                  @mouseleave="link.text ? (copyText = 'Copiar') : ''"
                  :class="mobile ? 'texto-footer' : ''"
                >
                  {{ link.name }}
                </span>
              </div>
            </template>
            <span v-if="link.span">{{ link.span }}</span>
            <span v-else>{{ copyText }}</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-col class="primary text-center white--text" cols="12">
        ©
        <strong class="white-link">
          <a
            class="white--text text-decoration-none"
            href="https://www.maximizaerp.cl/"
            target="_blank"
          >
            Maximiza
          </a>
        </strong>
        {{ new Date().getFullYear() }}
        <v-tooltip color="white" top content-class="version-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              icon
              small
              absolute
              right
            >
              <v-icon small left> mdi-help-box </v-icon>
            </v-btn>
          </template>
          <span>{{ build }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    copyText: "Copiar",
    build: process.env.VUE_APP_BUILD_DATE,
    links: [
      {
        name: "info@coonfia.cl",
        icon: "mdi-email",
        text: "info@coonfia.cl"
      },
      {
        icon: "mdi-map-marker",
        name: "Casa Matriz: Prat 845, Valparaíso",
        link: "https://goo.gl/maps/wnY6TZ9PnAbqkQkM9",
        span: "Ver en Google Maps"
      },
      {
        icon: "mdi-phone",
        name: "(56 32) 2469768 - (56 32) 2469769",
        text: "(56 32) 2469768 - (56 32) 2469769"
      }
    ]
  }),
  methods: {
    ...mapActions(["switchDrawer"]),
    copiarTexto(value) {
      navigator.clipboard.writeText(value);
      this.copyText = "Copiado !";
    }
  },
  computed: {
    mobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    mostrarFooter() {
      let ruta = this.$route.name;
      return ruta && ruta !== "login" && ruta !== "crear_contrasena";
    }
  },
  mounted() {
    if (this.mobile) {
      this.switchDrawer(false);
    }
  }
};
</script>
<style scoped lang="css">
.texto-footer {
  width: min-content;
  white-space: normal;
  flex: auto;
}
.version-tooltip {
  font-size: 10px !important;
  color: black;
  padding: 0px 5px !important;
}
</style>
